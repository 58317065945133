import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/search.svg'


const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/search.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Пребарување</h2>
                            <p id="escape2">По некое време, вашиот работен простор ќе биде преполнет со десетици проекти и стотици задачи и датотеки. Ова е причината зошто Тафома е опремена со сеопфатен пребарувач за да бидете сигурни дека можете да најдете сè што ви треба за неколку секунди. Само напишете збор или фраза и пребарувачот ќе пребараво секоја задача, проект, коментар, разговори и низ документите кои што сте ги прикачиле.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne