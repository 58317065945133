import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/spreadsheets.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/spreadsheet.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} /> Табели</h2>
                            <p id="escape2">Тафома табелите им овозможуваат на корисниците да го иксористат потенцијалот на податоците, користејќи формули низ мрежа од ќелии. Податоците се вметнуваат во поединечни ќелии, во редови или колони, овозможувајќи им да се сортираат и филтрираат, а потоа да се прикажат во визуелна презентација (табели, графикони, групирани колони). Табелите на Тафома препорачуваат графикони најпогодни за типот на податоци што се прикажуваат на оската X и Y.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                            {/* <img className="ptb-50" src={feature} alt="banner" /> */}
                            <StaticImage src={'../../assets/images/features/spreadsheet.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne