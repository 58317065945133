import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import feature from '../../assets/images/features/tasks.png'
import icon from '../../assets/images/features/icons/tasks.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            {/* <img className="features" src={feature} alt="banner" /> */}
                            <StaticImage src={'../../assets/images/features/tasks.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        loading="eager"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Задачи</h2>
                            <p id="escape2">Модулот за задачи е основата функционалност на Тафома. Можете да креирате задачи за себе или да доделите задачи на кој било од вашиот тим. Инкорпорирајќи ги методологиите, ви даваме максимална флексибилност. Задачите може да се состојат од: опис, коментари, списоци со задачи, трошоци и секции за менаџирање со времето. Понатаму, можете да имплементирате датуми за почеток и доспевање, да означите приоритетно ниво на проектот, да поставите потсетници, па дури и да го покажете процентот до кој е завршена задачата. Се разбира, можете да прикачите и датотеки, вклучувајќи слики, документи и PDF-датотеки.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne