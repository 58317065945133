import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/presentations.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25 bg-e7edf9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/presentation.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} /> Презентации</h2>
                            <p id="escape2">Презентациите на Tafoma може да се користат на повеќе различни ефективни начини за комуникација со вашиот тим. Слајдовите се целосно приспособливи за да одговараат на вашите потреби. Во зависност од вашиот пристап, можеби ќе сакате да имате презентација со текст, со слика или пак комбинација од двете. Комбинирањето на двата типа му овозможува на тимот бенефит од визуелните помагала и од белешките.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                            {/* <img className="ptb-50" src={feature} alt="banner" /> */}
                            <StaticImage src={'../../assets/images/features/presentation.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne