import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/drive.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/drive.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} />Drive</h2>
                            <p id="escape2"> Drive на Тафома има едноставна функција: безбедно складирање и непречено синхронизирање на сите ваши документи и датотеки на облакот за да може да им се пристапи каде и да сте. Од Drive можете брзо и лесно да споделувате датотеки или папки со вашиот тим. Тафома користи двојно енкриптирање, така што никогаш не треба да се грижите за приватноста.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne