import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/chat.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/chat.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} /> Chat</h2>
                            <p id="escape2">Во ерата на работење од дома, ефективна алатка за разговори може брзо да стане столбот на вашиот бизнис. Chat алатката на Тафома е сеопфатна, мрежна алатка за тимска соработка во реално време, со можности за споделување датотеки и им овозможува на своите корисници да разменуваат текстуални пораки, слики, видеа и линкови.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne