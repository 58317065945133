import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/social_wall.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/social_wall.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} />Социјален sид</h2>
                            <p id="escape2">Нашиот социјален ѕид е изграден од ,,темел‘‘, збогатувајќи ја компаниската култура со тоа што му овозможува на вашиот тим едно поповрзано, поинформирано и похармонично искуство. Социјалниот ѕид на Тафома е хит меѓу корисниците. Тој додава уникатност во комуникацијата со луѓето од опкружувањето, контактите, социјалните настани и happy hours. Ќе ви се допадне колку е едноставно и задоволувачко да креирате објава која може да ја види целиот ваш тим.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne