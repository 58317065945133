import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/video_meeting.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/video_meeting.png'} 
                                        alt="" 
                                        className="features"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id = "escape"><img className="icon" src={icon} />Видео состаноци</h2>
                            <p id="escape2">Користете ја нашата апликација за видео-конференција за ефикасно да соработувате remote со сите од вашиот тим, како на десктоп така и на мобилен уред. Можете дури и да поканите некој кој што не е претплатен на Tafoma, на состанок. Видео конференциите на Тафома ви овозможуваат да ја проширите амбицијата на вашиот бизнис без да ја загрозите продуктивноста и ангажираноста. Тафома ви овозможува да имате неограничено време за споделување екран до 50 корисници.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne