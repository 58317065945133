import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/address_book.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25 bg-e7edf9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/features/address_book.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} />Адресар</h2>
                            <p id="escape2">Внесете ги сите ваши контакти на едно место. Со оваа функција можете исто така да ги групирате вашите контакти по клиенти, добавувачи, изведувачи, подизведувачи, пациенти итн. Дополнително, можете да поврзете задачи не само со проект, туку и со контакт и да видите со колку задачи е поврзан контактот и колку задачи се сè уште активни.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                        <StaticImage src={'../../assets/images/features/address_book.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne