import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/projects.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25 bg-e7edf9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/project.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} />Проекти</h2>
                            <p id="escape2">Ако ви треба преглед на сите ваши задачи, можете лесно да го направите тоа со нашата функција Проекти, која ви овозможува да групирате поврзани задачи и да го следите нивниот прогрес. Проекти е  ефикасна карактеристика, каде што можете да видите како се одвива проектот - од започнување до завршување. Тафома ве спасува од гломазни платформи за управување со проекти и е врвен економичен софтвер наменет за тимска работа.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                        <StaticImage src={'../../assets/images/features/project.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne