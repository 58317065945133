import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import icon from '../../assets/images/features/icons/calendar.svg'

const ServicesOne = () => {
    return (
        <section className="project-start-area ptb-25 bg-e7edf9">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 mobile-only">
                        <div className="text-center">
                        <StaticImage src={'../../assets/images/features/calendar.png'} 
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 id="escape"><img className="icon" src={icon} /> Календар</h2>
                            <p id="escape2">Тафома е платформа за менаџирање на времето, од временски блокови до податоци за продуктивност. Нашиот календар обезбедува јасен преглед на времепловот на задачите, потсетниците и вашата севкупна агенда. Лесно можете да ги споделите настаните и известувањата со вашите колеги, а тие ќе можат да ги видат во нивниот календар. Функцијата за закажување настан на Тафома и поканите не се ограничени само на членовите на тимот, туку и на надворешни учесници.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 desktop-only">
                        <div className="text-center features">
                        <StaticImage src={'../../assets/images/features/calendar.png'} 
                                        alt="" 
                                        className="features ptb-50"
                                        imgClassName="features-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne